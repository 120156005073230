"use client";

import Image from "next/image";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

const Banner = () => {
  const controls = useAnimation();
  const { ref, inView } = useInView({ triggerOnce: true });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
  };

  const bannerItems = [
    { icon: "/assets/icons/icon1.png", title: "Project Complete", number: 25 },
    { icon: "/assets/icons/icon2.png", title: "Clients satisfaits", number: 30 },
    { icon: "/assets/icons/icon3.webp", title: "Partenaires", number: 6 },
    { icon: "/assets/icons/icon4.webp", title: "Consultations", number: 120 },
  ];

  return (
    <div
      ref={ref}
      className="w-full flex banner-bg items-center justify-center gap-6 p-6 min-h-[415px]"
    >
      <motion.div
        className="w-full flex flex-col lg:flex-row justify-center gap-4 xl:gap-44 items-center"
        initial="hidden"
        animate={controls}
        variants={itemVariants}
      >
        {bannerItems.map((item, index) => (
          <motion.div
            key={index}
            className="flex flex-col items-center xl:items-start justify-center gap-2 relative"
            variants={itemVariants}
          >
            <Image
              src={item.icon}
              height={50}
              width={50}
              priority
              alt={item.title}
              className="w-auto h-auto"
            />
            <h1 className="text-[#fff] text-xl xl:text-[51px] leading-[66px] font-medium">
              {item.number}
            </h1>
            <span className="absolute text-[#fff] text-xl xl:text-[40px] leading-[18px] font-bold top-[32%] right-[5%]">
              +
            </span>
            <span className="text-[#fff] text-[18px] leading-[18px] font-normal">
              {item.title}
            </span>
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
};

export default Banner;
