declare global {
  interface Window {
    hbspt: any;
  }
}

'use client';
import { useEffect } from 'react';

const HubSpotForm = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js-eu1.hsforms.net/forms/embed/v2.js';
    script.async = true;
    script.onload = () => {
      if ((window as any).hbspt) {
        window.hbspt.forms.create({
          region: 'eu1',
          portalId: '26821212',
          formId: 'c35e3c3e-269f-4f24-b3c1-a2a191ae7e06',
          target: '#hubspotFormContainer',  // Ensure it targets this specific div
        });
      }
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <div id="hubspotFormContainer"></div>;  // Adjust the ID to be specific for this instance
};

export default HubSpotForm;
