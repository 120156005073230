"use client";

import Image from "next/image";
import Link from "next/link";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

const WhatWeDo = () => {
  const controls = useAnimation();
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1, // Adjust this threshold as needed
  });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const serviceVariants = {
    hidden: { opacity: 0, y: 10 },
    visible: (i: number) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.1, // Adjust this delay as needed for staggered effect
        duration: 0.2,
      },
    }),
  };

  const services = [
    {
      icon: "/assets/services/dev.png",
      title: "Développement web et mobile",
      description:
        "Nous créons des sites web et des applications mobiles performants et intuitifs, adaptés aux besoins spécifiques de votre entreprise. De la conception à la mise en ligne, notre équipe de développeurs expérimentés ...",
      route: "/service/developpement-web-et-mobile",
    },
    {
      icon: "/assets/services/conseil.png",
      title: "Conseil en Transformation Digitale",
      route: "/service/conseil-en-transformation-digitale",
      description:
        "Nos consultants experts vous accompagnent dans votre transformation digitale en définissant des stratégies adaptées à vos objectifs. Nous vous aidons à intégrer les technologies de pointe ...",
    },
    {
      route: "/service/intelligence-artificielle-et-machine-learning",
      icon: "/assets/services/machine.png",
      title: "Intelligence Artificielle et Machine Learning",
      description:
        "Chez Lexdill, nous intégrons l'intelligence artificielle et le machine learning dans vos projets pour améliorer l'efficacité et offrir des solutions innovantes. Nos experts travaillent avec vous pour créer des systèmes intelligents ...",
    },
    {
      route: "/service/departement-design",
      icon: "/assets/services/design.png",
      title: "Departement design",
      description:
        "Notre département design se consacre à créer des expériences utilisateur exceptionnelles. De l'identité visuelle aux interfaces utilisateur, nous concevons des solutions esthétiques et fonctionnelles qui captivent votre audience.",
    },
    {
      icon: "/assets/services/cyber.png",
      title: "Cybersécurité",
      route: "/service/cybersecurite",
      description:
        "Protégez vos informations sensibles et assurez la continuité de vos activités avec nos solutions de cybersécurité robustes. Nous offrons des audits de sécurité, des tests de pénétration, et des services de surveillance pour prévenir...",
    },
    {
      icon: "/assets/services/marketing.png",
      title: "Marketing Strategy",
      route: "/service/strategie-marketing",
      description:
        "Boostez votre visibilité et atteignez de nouveaux marchés grâce à nos services de marketing digital. Nous offrons des stratégies complètes incluant la gestion des réseaux sociaux, le référencement SEO, les campagnes publicitaires, et bien plus.",
    },
    {
      route: "/service/solution-blockchain",
      icon: "/assets/services/blockchain.png",
      title: "Solution Blockchain",
      description:
        "Embrassez l'avenir avec nos solutions blockchain. Nous développons des applications décentralisées et des contrats intelligents pour sécuriser vos transactions et améliorer la transparence de vos opérations.",
    },
    {
      route: "/service/projet-web3",
      icon: "/assets/services/web3.png",
      title: "Projet Web3",
      description:
        "Pionniers dans l'univers du Web3, nous créons des projets basés sur les technologies décentralisées. Découvrez comment le Web3 peut transformer votre entreprise avec des solutions innovantes et résilientes",
    },
    {
      route: "/service/cloud-computing",
      icon: "/assets/services/cloud.png",
      title: "Cloud Computing",
      description:
        "Optimisez votre infrastructure informatique avec nos solutions de cloud computing. Nous vous aidons à migrer vers le cloud, à gérer vos ressources et à garantir une accessibilité et une scalabilité maximales, tout en réduisant les coûts opérationnels.",
    },
  ];

  return (
    <section
      ref={ref}
      id="portfolio"
      className="w-full flex flex-col justify-start gap-10 min-h-screen items-center servicesSection-bg p-8"
    >
      <div className="inline-flex gap-4 items-center">
        <div className="w-[15px] h-[3px] bg-[#2F479E]"></div>
        <h1 className="text-xl text-[#2F479E] font-normal leading-[21px] uppercase">
          ce que nous faisons
        </h1>
        <div className="w-[15px] h-[3px] bg-[#2F479E]"></div>
      </div>
      <h2 className="text-center text-[#333333] font-bold 2xl:text-[45px]">
        Nous fournissons des services <br />
        exclusifs pour votre entreprise
      </h2>
      <div className="w-full 2xl:w-[70%] p-4 grid m-auto gap-12 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:pl-20">
        {services.map((service, index) => (
          <motion.div
            key={index}
            className="max-lg:justify-self-center flex flex-col rounded-[20px] bg-white p-8 items-center justify-evenly gap-1 max-w-[350px] xl:w-[416px] md:h-[470px] cursor-pointer"
            custom={index}
            initial="hidden"
            animate={controls}
            variants={serviceVariants}
          >
            <Image
              src={service.icon}
              alt={service.title}
              className="w-16 h-16"
              width={64}
              height={64}
              loading="lazy"
            />
            <h3 className="text-[#2F479E] font-bold text-center text-lg">
              {service.title}
            </h3>
            <p className="text-[#696969] text-base 2xl:leading-[30px] text-center">
              {service.description}
            </p>
            <div className="inline-flex items-center gap-4 border-[2px] border-[#ECECEC] p-3 cursor-pointer ">
              <Link href={service.route} className="text-base text-[#096BD8]">
                En savoir d'avantage
              </Link>
              <div style={{ width: "20px", height: "20px" }}>
                <Image
                  src="/assets/icons/arrow.png"
                  alt="arrow"
                  width={20}
                  height={20}
                  style={{ width: "auto", height: "auto" }}
                />
              </div>
            </div>
          </motion.div>
        ))}
      </div>
    </section>
  );
};

export default WhatWeDo;
