"use client";

import Image from "next/image";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect, memo } from "react";
import Link from "next/link";

const LandingComponent = () => {
  const controls = useAnimation();
  const { ref, inView } = useInView({ triggerOnce: true });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [inView, controls]);

  const textVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.2 } },
  };

  const floatVariants = {
    float: {
      y: [0, -10, 0],
      transition: {
        duration: 2,
        repeat: Infinity,
        ease: "easeInOut",
      },
    },
  };

  // Memoized static content to avoid unnecessary re-renders
  const StaticContent = memo(() => (
    <div className="w-full flex justify-center sm:justify-start items-center p-4 gap-10 mt-10">
      <button className="font-semibold text-base sm:text-xl text-[#096BD8] rounded-[20px] bg-white shadow py-1 px-4 h-14">
        <Link href="/servicepage">Découvrez plus</Link>
      </button>
      <button className="font-semibold text-base sm:text-xl text-white rounded-[20px] bg-[#096BD8] shadow py-1 px-4 h-14">
        <Link href="/contact">Contactez-nous</Link>
      </button>
    </div>
  ));

  return (
    <section
      ref={ref}
      className="w-full flex flex-col lg:flex-row items-center justify-center gap-6 p-6"
    >
      <motion.div
        className="flex flex-col items-start justify-start"
        initial="hidden"
        animate={controls}
        variants={textVariants}
      >
        <div className="flex flex-col justify-start items-start gap-2">
          <h1 className="max-sm:text-2xl max-sm:text-start 2xl:text-5xl font-bold">
            Votre partenaire en{" "}
          </h1>
          <h2 className="text-[#096bd8] max-sm:text-xl max-sm:text-start sm:text-3xl lg:text-5xl 2xl:text-[96px] 2xl:leading-[128px] font-bold text-center lg:text-start">
            transformation <br className="hidden lg:block" /> digitale
          </h2>
        </div>
        <p className="text-[#000] mt-5 text-base sm:text-xl 2xl:text-[32px] max-sm:text-start max-w-[600px] xl:leading-[32px]">
          Lexdill est une société informatique innovante basée en France,
          spécialisée dans la transformation digitale et les solutions
          technologiques sur mesure.
        </p>
        <div className="mx-auto block sm:hidden">
          <Image
            src="/assets/human_mobile.webp"
            alt="Lexdill human"
            width={393}
            height={497}
            sizes="(max-width: 640px) 100vw, 393px"
            className="size-auto"
            loading="lazy"
          />
        </div>
        <StaticContent />
      </motion.div>

      <div className="flex justify-center hidden sm:block items-center p-2 w-full lg:max-w-[40%] 2xl:w-auto relative">
        <motion.div
          className="absolute top-5 left-5 lg:top-10 lg:left-10"
          variants={floatVariants}
          animate="float"
        >
          <Image
            src="/assets/stats.png"
            alt="Lexdill stats"
            width={158}
            height={158}
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
            className="w-[50px] h-auto sm:w-[75px] lg:w-[100px] 2xl:w-[150px]"
            loading="lazy"
          />
        </motion.div>
        <div className="image-container">
          <Image
            src="/assets/human.webp"
            alt="Lexdill human"
            width={896}
            height={990}
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
            className="w-[250px] h-auto sm:w-[400px] lg:w-[500px] 2xl:w-[896px]"
            loading="lazy"
          />
        </div>

        <motion.div
          className="absolute bottom-5 right-5 lg:bottom-10 lg:right-10"
          variants={floatVariants}
          animate="float"
        >
          <Image
            src="/assets/reusite.png"
            alt="Lexdill reusite"
            width={270}
            height={107}
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
            className="w-[100px] h-auto sm:w-[150px] lg:w-[200px] 2xl:w-[254px]"
            loading="lazy"
          />
        </motion.div>
      </div>
    </section>
  );
};

export default LandingComponent;
