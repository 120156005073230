import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ubuntu/workDir/Lexdill/app/components/Banner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ubuntu/workDir/Lexdill/app/components/Blogs.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ubuntu/workDir/Lexdill/app/components/Contact.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ubuntu/workDir/Lexdill/app/components/HubSpotForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ubuntu/workDir/Lexdill/app/components/LandingComponent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ubuntu/workDir/Lexdill/app/components/WhatsAppCall.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ubuntu/workDir/Lexdill/app/components/WhatWeDo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ubuntu/workDir/Lexdill/app/components/WhoWeAre.tsx");
