"use client";

import { lazy, Suspense } from "react";
import Image from "next/image";
import { motion } from "framer-motion";

const HubSpotMeetings = lazy(() => import("./HubSpotMeetings"));

const Contact = () => {
  return (
    <section className="w-full min-h-[80vh] bg-[#096BD8] flex justify-center items-center p-4">
      <div
        id="contact"
        className="xl:w-[80%] w-full flex flex-col xl:flex-row items-center justify-evenly"
      >
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          <Image
            src="/assets/icons/contact.png"
            alt="Contact Icon"
            width={500}
            height={500}
            loading="lazy"
          />
        </motion.div>
        <Suspense fallback={<div>Loading...</div>}>
          <motion.div
            className="sm:w-full xl:w-1/2"
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
          >
            <HubSpotMeetings />
          </motion.div>
        </Suspense>
      </div>
    </section>
  );
};

export default Contact;
