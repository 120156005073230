"use client";

import Image from "next/image";
import { motion } from "framer-motion";
import Link from "next/link";

const Blogs = () => {
  const blogs = [
    {
      src: "/assets/blogs/blog1.png",
      title: "Les Tendances du Développement Web en 2024",
      description:
        "En 2024, le développement web continue d'évoluer à un rythme rapide...",
      date: "05 Aug 2024",
      comments: "(03)",
      alt: "Blog 1",
      author: "Lexdill",
      route: "/blog/blog1",
    },
    {
      src: "/assets/blogs/blog2.png",
      title:
        "Comment l'Intelligence Artificielle Révolutionne le Marketing Digital",
      description:
        "L'Intelligence Artificielle (IA) transforme tous les aspects du marketing digital,...",
      date: "05 Aug 2024",
      comments: "(03)",
      alt: "Blog 2",
      author: "Lexdill",
      route: "/blog/blog2",
    },
    {
      src: "/assets/blogs/blog3.png",
      title:
        "Pourquoi la Sécurité dans le Cloud est Cruciale pour les Entreprises en 2024",
      description:
        "Avec l'adoption croissante du cloud computing, la sécurité dans le cloud est devenue une préoccupation majeure pour les entreprises...",
      date: "05 Aug 2024",
      comments: "(03)",
      alt: "Blog 3",
      author: "Lexdill",
      route: "/blog/blog3",
    },
  ];

  return (
    <section
      id="blogs"
      className="w-full min-h-[80vh] flex flex-col justify-start gap-0 pt-10 items-center p-2 2xl:p-8"
    >
      <div className="2xl:w-[66%] flex flex-col items-center justify-start p-8">
        <div className="inline-flex gap-4 items-center">
          <div className="w-[15px] h-[3px] bg-[#096BD8]"></div>
          <h1 className="text-xl text-[#096BD8] font-normal leading-[21px] uppercase">
            Nos Blogs
          </h1>
          <div className="w-[15px] h-[3px] bg-[#096BD8]"></div>
        </div>
        <h1 className="text-2xl font-semibold text-[#333333] 2xl:leading-[60px] mt-4 2xl:text-[54px]">
          Lis nos dernières <br /> Actualités et blogs
        </h1>
      </div>

      <div className="m-auto w-full flex flex-col lg:flex-row items-center justify-center gap-10 p-1 2xl:p-8">
        {blogs.map((blog, index) => (
          <motion.div
            key={index}
            className="flex flex-col justify-start items-center w-[300px] xl:w-[400px] xl:h-[600px]"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.2 }}
          >
            <div className="w-full flex justify-center items-start relative">
              <Image
                height={350}
                width={400}
                src={blog.src}
                alt={blog.alt}
                className="w-auto h-auto rounded-[20px]"
                loading="lazy" // Lazy loading images
              />
              <div className="hidden absolute -bottom-8 bg-[#ffffff] rounded-[20px] sm:inline-flex items-center gap-2 p-4 shadow">
                <Image
                  height={16}
                  width={16}
                  src="/assets/blogs/icon.webp"
                  alt="Icon"
                />
                <span>{blog.author}</span>
                <Image
                  height={16}
                  width={16}
                  src="/assets/blogs/date.png"
                  alt="Date"
                />
                <span>{blog.date}</span>
                <Image
                  height={16}
                  width={16}
                  src="/assets/blogs/message.webp"
                  alt="Comments"
                />
                <span>{blog.comments}</span>
              </div>
            </div>

            <div className="flex flex-col items-start justify-evenly min-h-80 p-4 lg:mt-10">
              <h1 className="text-[#333] text-base font-bold">{blog.title}</h1>
              <p className="text-[#696969] text-[14px] xl:leading-[30px]">
                {blog.description}
              </p>
              <div className="inline-flex items-center gap-4 border-[2px] border-[#096BD8] p-3 cursor-pointer rounded-3xl">
                <Link href={blog.route} className="text-base text-[#096BD8]">
                  En savoir Plus
                </Link>
                <Image
                  src="/assets/icons/arrow.png"
                  alt="arrow"
                  width={20}
                  height={20}
                  style={{ width: "auto", height: "auto" }}
                />
              </div>
            </div>
          </motion.div>
        ))}
      </div>
    </section>
  );
};

export default Blogs;
