"use client";

import Link from "next/link";
import Image from "next/image";
import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const WhatsAppCall = () => {
  const controls = useAnimation();
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
  };

  return (
    <section
      ref={ref}
      className="w-full flex flex-col items-center lg:flex-row justify-around p-2 md:p-8 lg:p-24 min-h-[393px] banner-bg2 mt-0"
    >
      <motion.div
        className="flex flex-col items-start justify-normal gap-10"
        initial="hidden"
        animate={controls}
        variants={fadeInUp}
      >
        <h1 className="text-[#ffffff] font-normal leading-[21px] uppercase text-sm">
          as-tu un projet ?
        </h1>
        <h2 className="text-[#ffffff] font-medium leading-[21px] uppercase text-xl xl:text-[44px] xl:leading-[54px]">
          Parlons de solutions <br />
          commerciales avec nous
        </h2>
      </motion.div>
      <motion.div
        className="flex flex-col sm:flex-row justify-center items-center gap-10"
        initial="hidden"
        animate={controls}
        variants={fadeInUp}
        transition={{ delay: 0.3 }}
      >
        <Link
          href={"/#contact"}
          className="bg-[#ffffff] rounded-[20px] flex justify-center items-center gap-1 h-[61px] w-[198.69px]"
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <span className="text-[#333333] font-normal text-sm uppercase">
              Contactez nous
            </span>
            <div
              style={{
                width: "12px",
                height: "14px",
                marginLeft: "4px",
                marginTop: "5px",
              }}
            >
              <Image
                src="/assets/icons/blackarrow.png"
                alt="arrow"
                width={12}
                className="w-auto h-auto"
                height={14}
               
              />
            </div>
          </div>
        </Link>
        <div className="inline-flex items-center">
          <Image
            src="/assets/icons/call.png"
            alt="call"
            width={12}
            height={14}
            style={{ width: "auto", height: "auto" }}
          />
          <Link
            href={`tel:+33189480112`} // Use 'tel:' protocol
            className="text-[#ffffff] text-sm"
          >
            +33 189480112
          </Link>
        </div>
      </motion.div>
    </section>
  );
};

export default WhatsAppCall;
