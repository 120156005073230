"use client";

import Image from "next/image";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import Link from "next/link";

const WhoWeAre = () => {
  const controls = useAnimation();
  const { ref, inView } = useInView({ triggerOnce: true });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const fadeInUpVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
  };

  const valeurs = [
    {
      icon: "/assets/icons/innovation.png",
      title: "Innovation",
      description: "Nous croyons en l'innovation comme clé de la réussite.",
    },
    {
      icon: "/assets/icons/achievements.png",
      title: "Excellence",
      description:
        "Nous nous engageons à fournir des solutions de haute qualité.",
    },
    {
      icon: "/assets/icons/team.png",
      title: "Proximité",
      description:
        "Nous travaillons en étroite collaboration avec nos clients pour comprendre et répondre à leurs besoins spécifiques.",
    },
    {
      icon: "/assets/icons/advocacy.png",
      title: "Intégrité",
      description:
        "Nous respectons les plus hauts standards d'éthique et de transparence.",
    },
  ];

  return (
    <section
      ref={ref}
      className="w-full flex flex-col xl:flex-row justify-center gap-10 min-h-screen items-center p-4"
    >
      <motion.div
        className="flex flex-col items-center xl:items-start gap-6 justify-around w-full p-1 xl:w-[30%]"
        initial="hidden"
        animate={controls}
        variants={fadeInUpVariants}
      >
        <motion.div
          className="inline-flex gap-4 items-center"
          variants={fadeInUpVariants}
        >
          <div className="w-[15px] h-[3px] bg-[#2F479E]"></div>
          <div className="w-[40px] h-[3px] bg-[#2F479E]"></div>
          <motion.h2
            className="text-xl text-[#2F479E] font-normal leading-[21px] uppercase"
            variants={fadeInUpVariants}
          >
            Qui sommes-nous
          </motion.h2>
        </motion.div>

        <motion.h3
          className="text-2xl font-semibold text-[#333] 2xl:leading-[55px] 2xl:text-[54px]"
          variants={fadeInUpVariants}
        >
          Plus de 10 ans que nous fournissons <br className="hidden xl:block" />
          <span className="text-2xl font-semibold text-[#096BD8] 2xl:text-[46px]">
            des Solutions informatiques
          </span>
        </motion.h3>

        <motion.p
          className="text-[#696969] text-base font-normal leading-7"
          variants={fadeInUpVariants}
        >
          Chez Lexdill, nous nous engageons à aider les entreprises à naviguer
          dans l'univers complexe de la technologie moderne. Notre équipe
          d'experts travaille en étroite collaboration avec chaque client pour
          comprendre leurs besoins uniques et développer des solutions adaptées
          qui stimulent leur croissance et améliorent leur efficacité
          opérationnelle.
        </motion.p>

        <motion.div
          className="inline-flex items-center gap-4 border-b border-b-[#096BD8] cursor-pointer"
          variants={fadeInUpVariants}
        >
          <Link href={"/about"} className="text-base text-[#096BD8]">
            En savoir plus
          </Link>
          <div style={{ width: "20px", height: "20px" }}>
            <Image
              src="/assets/icons/arrow.png"
              height={20}
              width={20}
              alt="arrow"
              style={{ width: "auto", height: "auto" }}
            />
          </div>
        </motion.div>
      </motion.div>

      <motion.div
        className="flex justify-center items-center p-2 w-full xl:w-[29%]"
        initial="hidden"
        animate={controls}
        variants={fadeInUpVariants}
      >
        <Image
          src="/assets/whoweare.png"
          height={400}
          width={400}
          alt="Who We Are"
          priority
          style={{ width: "auto", height: "auto" }}
        />
      </motion.div>

      <motion.div
        className="flex flex-col items-start justify-start gap-10 w-full lg:w-[35%] 2xl:w-[29%] p-4"
        initial="hidden"
        animate={controls}
        variants={fadeInUpVariants}
      >
        <motion.h3
          className="text-2xl font-semibold text-[#333] 2xl:leading-[60px] 2xl:text-[54px]"
          variants={fadeInUpVariants}
        >
          Nos
          <span className="text-2xl font-semibold pl-1 text-[#096BD8] 2xl:text-[54px]">
              Valeurs
          </span>
        </motion.h3>

        <div className="grid grid-cols-1 mx-auto xl:grid-cols-2 xl:grid-rows-2 gap-5 p-4">
          {valeurs.map((valeur, index) => (
            <motion.div
              key={index}
              className="flex max-lg:justify-self-center rounded-[20px] flex-col items-center gap-2 p-4 bg-[#FFF] shadow"
              style={{ boxShadow: "0px 4px 4px 4px rgba(210, 210, 210, 0.25)" }}
              variants={fadeInUpVariants}
            >
              <div className="w-full flex items-center justify-start gap-2">
                <Image
                  src={valeur.icon}
                  height={40}
                  width={40}
                  alt={valeur.title}
                />
                <motion.h4
                  className="text-[#096BD8] font-semibold text-2xl"
                  variants={fadeInUpVariants}
                >
                  {valeur.title}
                </motion.h4>
              </div>
              <motion.p
                className="text-[#000000] text-start text-base"
                variants={fadeInUpVariants}
              >
                {valeur.description}
              </motion.p>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </section>
  );
};

export default WhoWeAre;
